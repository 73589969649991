<template>
  <figure class="icon">
    <img src="/static/images/logo.svg" alt="etikaego" />
  </figure>
</template>

<script>
export default {
  name: "LogoIcon",
};
</script>
