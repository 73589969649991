<template>
  <nav class="nav" :class="{ 'nav--active': active }" v-if="categoriesSorted">
    <div class="nav__side" :class="{ 'nav__side--active': activeCategory === null }">
      <ul class="nav__menu">
        <li class="nav__menu-item" v-for="(category, i) in categoriesSorted" :key="i">
          <a
            href="#"
            @click.prevent="setActiveCategory(i)"
            class="nav__menu-link"
            :class="{ 'nav__menu-link--active': activeCategory === i }"
          >
            <span>{{ category.title }}</span>
            <BreadcrumbArrowIcon />
          </a>
        </li>
      </ul>
      <router-link :to="{ name: 'courses' }" class="btn btn--main btn--small">Все курсы</router-link>
    </div>
    <div class="nav__content" :class="{ 'nav__content--active': activeCategory !== null }">
      <a
        class="nav__content-prev"
        @click.prevent="activeCategory = null"
        v-if="categoriesSorted[activeCategory]"
      >
        <BreadcrumbArrowIcon />
        <span>{{ categoriesSorted[activeCategory].title }}</span>
      </a>
      <div class="nav__list" v-if="beginner_courses.length">
        <span class="nav__list-title">Для начинающих</span>
        <ul class="nav__list-item">
          <li v-for="(course, i) in beginner_courses" :key="i">
            <router-link
              :to="{ name: 'course', params: { category: course.category.link, link: course.link } }"
              class="nav__list-link"
            >
              {{ course.title }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="nav__list" v-if="advanced_courses.length">
        <span class="nav__list-title">Для продвинутых</span>
        <ul class="nav__list-item">
          <li v-for="(course, i) in advanced_courses" :key="i">
            <router-link
              :to="{ name: 'course', params: { category: course.category.link, link: course.link } }"
              class="nav__list-link"
            >
              {{ course.title }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="nav__list" v-if="expert_courses.length">
        <span class="nav__list-title">Для экспертов</span>
        <ul class="nav__list-item">
          <li v-for="(course, i) in expert_courses" :key="i">
            <router-link
              :to="{ name: 'course', params: { category: course.category.link, link: course.link } }"
              class="nav__list-link"
            >
              {{ course.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import MENU_CATEGORIES from "@/graphql/queries/categories.graphql";
import BreadcrumbArrowIcon from "components/svg/BreadcrumbArrowIcon.vue";

export default {
  name: "MainMenuComponent",
  props: {
    active: {
      type: Boolean,
    },
  },
  components: { BreadcrumbArrowIcon },
  data() {
    return {
      activeCategory: 0,
      categories: [],
    };
  },
  created() {
    this.$apollo
      .query({
        query: MENU_CATEGORIES,
        variables: {
          table_name: "courses",
        },
      })
      .then(({ data }) => {
        this.categories = data.categories;
      });
  },
  mounted() {
    // Если с телефона
    if (this.$store.getters.mobileCheck) {
      this.activeCategory = null;
    }
  },
  computed: {
    categoriesSorted() {
      if (!this.categories || !this.categories.length) {
        return [];
      }
      return this.categories
        .filter((category) => category.courses.length)
        .filter((category) => category.courses.filter((course) => course.type_level).length)
        .filter((category) => {
          return category.courses.filter((course) => {
            // Курсы для новичков
            let beginner = course.type_level.code === this.$store.state._types.CODE_COURSE_EASY;
            // Курсы для продвинутых
            let advanced = course.type_level.code === this.$store.state._types.CODE_COURSE_MEDIUM;
            // Курсы для экспертов
            let expert = course.type_level.code === this.$store.state._types.CODE_COURSE_HARD;
            return beginner || advanced || expert;
          }).length;
        });
    },
    /**
     * Курсы для новичков CODE_COURSE_EASY
     * @returns Array
     */
    beginner_courses() {
      if (!this.categoriesSorted[this.activeCategory]) {
        return [];
      }
      return this.categoriesSorted[this.activeCategory].courses.filter((course) => {
        return course.type_level.code === this.$store.state._types.CODE_COURSE_EASY;
      });
    },
    /**
     * Курсы для продвинутых CODE_COURSE_MEDIUM
     * @returns Array
     */
    advanced_courses() {
      if (!this.categoriesSorted[this.activeCategory]) {
        return [];
      }
      return this.categoriesSorted[this.activeCategory].courses.filter((course) => {
        return course.type_level.code === this.$store.state._types.CODE_COURSE_MEDIUM;
      });
    },
    /**
     * Курсы для экспертов CODE_COURSE_HARD
     * @returns Array
     */
    expert_courses() {
      if (!this.categoriesSorted[this.activeCategory]) {
        return [];
      }
      return this.categoriesSorted[this.activeCategory].courses.filter((course) => {
        return course.type_level.code === this.$store.state._types.CODE_COURSE_HARD;
      });
    },
  },
  methods: {
    setActiveCategory(i) {
      this.activeCategory = i;
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.nav {
  visibility hidden
  opacity 0
  transform translateY(100%)
  absolute left bottom right
  width 100%
  background: var(--white);
  box-sizing: border-box;
  box-shadow: 0 16px 24px var(--black_o06), 0px 2px 6px var(--black_o04), 0px 0px 1px var(--black_o04);
  border-radius: var(--radius);
  z-index -1
  display grid
  grid-template-columns 271px 1fr
  overflow: hidden;
  transition var(--transition)
  +below(768px) {
    border none
    grid-template-columns 1fr
    margin 0 -32px
    width: auto
    border-radius 0
  }
  +below(420px) {
    margin 0 -20px
  }

  &--active {
    visibility visible
    opacity 1
    z-index 10
    transform translateY(calc(100% + 20px))
    +below(768px) {
      transform translateY(100%)
    }
  }

  &__side {
    display flex
    flex-direction column
    box-sizing border-box
    border-right 1px solid var(--gray_divider);
    height 100%
    width 100%
    transition var(--transition)
    +below(768px) {
      &:not(&--active) {
        visibility hidden
        opacity 0
        position absolute
      }
    }

    .btn {
      text-align left
      justify-content: left;
      margin auto 20px 20px
      +below(768px) {
        margin auto 32px 30px
      }
      +below(420px) {
        margin auto 20px 30px
      }
    }
  }

  &__menu {
    display flex
    flex-direction column
    margin-bottom 40px
    max-height 450px
    overflow-y auto
    +below(768px) {
      max-height none
      margin-bottom 20px
    }

    &-item {
      width 100%
      display flex
      align-items center
      justify-content flex-start
      flex-shrink 0
    }

    &-link {
      width 100%
      padding 15px 25px
      font-weight: normal;
      font-size: 1.125em;
      line-height: 22px;
      color var(--black)
      word-break break-word
      display flex
      justify-content space-between
      gap 20px
      +below(768px) {
        padding 15px 32px
      }
      +below(420px) {
        padding 15px 20px
      }

      &--active {
        background: var(--gray_light);
      }

      .icon {
        width 24px
        height 24px
        flex-shrink 0
        +above(769px) {
          display none
        }

        svg {
          width 100%
          height 100%
          svg(var(--black))
        }
      }
    }
  }

  &__content {
    width 100%
    height 100%
    display grid
    grid-template-columns repeat(3, 1fr)
    padding 40px
    grid-gap 80px
    +below(768px) {
      grid-template-columns: 1fr;
      padding: 0 32px 30px;
      grid-gap: 40px;
      transition var(--transition)
      &:not(&--active) {
        visibility hidden
        opacity 0
        position relative
        z-index 2
      }
    }
    +below(420px) {
      padding: 0 20px 30px;
    }

    &-prev {
      display flex
      align-items center
      gap 10px
      margin-bottom -25px
      padding 16px 0
      font-weight: normal;
      font-size: 1.125em;
      line-height: 22px;
      color: var(--black);
      width 100%
      +above(769px) {
        display none
      }

      .icon {
        width 24px
        height 24px
        transform rotate(180deg)

        svg {
          width 100%
          height 100%
          svg(var(--black))
        }
      }
    }
  }

  &__list {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 25px

    &-title {
      font-weight: 600;
      font-size: 1.125em;
      line-height: 22px;
      color var(--black)
    }

    &-item {
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      gap 25px
    }

    &-link {
      font-weight: normal;
      font-size: 1.125em;
      line-height: 22px;
      color var(--black)
    }
  }
}
</style>
