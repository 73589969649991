<template>
  <div id="app">
    <vue-progress-bar />
    <HeaderComponent />
    <Transition mode="out-in" name="page">
      <RouterView :key="$route.fullPath" />
    </Transition>
    <Transition name="bounce">
      <ModalComponent />
    </Transition>
    <FooterComponent v-if="false" />
    <Notifications position="bottom right" />
  </div>
</template>

<script>
import HeaderComponent from "components/header/index.vue";
import FooterComponent from "components/FooterComponent.vue";
import ModalComponent from "components/modals/index.vue";
import ME from "gql/auth/me.graphql";
import LoginModal from "components/modals/components/LoginModal.vue";
import ResetPasswordModal from "components/modals/components/ResetPasswordModal.vue";
import EMAIL_CONFIRM from "gql/auth/EmailConfirm.graphql";
import AlertModal from "components/modals/components/AlertModal.vue";

export default {
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    let token = this.$store.state.cookies["apollo-token"];
    if (token) {
      this.$apollo.provider.defaultClient
        .query({
          query: ME,
          context: {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
        })
        .then(({ data }) => {
          this.$store.state.auth.user = data.me;
        })
        .catch(() => {
          this.$cookie.delete("apollo-token");
          delete this.$store.state.cookies["apollo-token"];
        });
    } else if (this.$route.meta.authRequired) {
      this.redirectToLogin();
    }
    // Полоска загрузки
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      // Если роут просит авторизацию
      if (to.meta.authRequired && !this.$store.state.auth.user) {
        // Показываем модалку входа
        this.$store.state._modals.push({
          component: LoginModal,
          options: {
            routeAfterLogin: to,
          },
        });
        // Отменяем переход
        next(false);
        this.$Progress.finish();
      } else {
        next();
      }
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    // Если была успешная оплата курса
    if (urlParams.get("payment_success")) {
      let isSuccess = urlParams.get("payment_success") === "1";
      this.$store.state._modals.push({
        component: AlertModal,
        options: {
          type: isSuccess ? "success" : "error",
          title: urlParams.get("title"),
          subtitle: urlParams.get("message"),
          buttonTitle: isSuccess ? "Перейти к курсу" : "Закрыть",
          callback: () => {
            if (isSuccess) {
              let token = this.$store.state.cookies["apollo-token"];
              if (this.user && token) {
                this.$router.push({
                  name: "account_education_list",
                  params: { id: urlParams.get("course_id") },
                });
              } else {
                this.$store.state._modals.push({
                  component: LoginModal,
                  options: {
                    routeAfterLogin: {
                      name: "account_education_list",
                      params: { id: urlParams.get("course_id") },
                    },
                  },
                });
              }
            }
            urlParams.delete("payment_success");
            urlParams.delete("title");
            urlParams.delete("message");
            urlParams.delete("course_id");
            history.replaceState(null, "", location.pathname + urlParams);
          },
        },
      });
    }
    // Если обновился email показываю уведомление в случае успеха (1) и удаляю параметр
    if (urlParams.get("success_email") === "1") {
      this.$notify({
        title: "Готово",
        text: "Email адрес успешно подтвержден",
        duration: 5000,
        speed: 200,
        type: "success",
      });
      urlParams.delete("success_email");
      history.replaceState(null, "", location.pathname + urlParams);
    }
    // Если был запрос на сброс пароля показываю модалку и удаляю параметр
    if (urlParams.get("password_reset_token")) {
      this.$store.state._modals.push({
        component: ResetPasswordModal,
        options: {
          token: urlParams.get("password_reset_token"),
        },
      });
      urlParams.delete("password_reset_token");
      history.replaceState(null, "", location.pathname + urlParams);
    }
    // Если зарегистрировались и подтвердили почту
    if (urlParams.get("register_confirm_token")) {
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: EMAIL_CONFIRM,
          variables: {
            token: urlParams.get("register_confirm_token"),
          },
        })
        .then(({ data }) => {
          if (data && data.EmailConfirm) {
            this.$notify({
              title: "Добро пожаловать",
              text: data.EmailConfirm.message,
              duration: 5000,
              speed: 200,
              type: "success",
            });
            this.$store.state.auth.user = data.EmailConfirm.user;
            this.$store.state.cookies["apollo-token"] = data.EmailConfirm.user.token;
            this.$cookie.set("apollo-token", data.EmailConfirm.user.token, {
              SameSite: "strict",
              Expires: new Date(2147483647 * 1000).toUTCString(),
            });
            urlParams.delete("register_confirm_token");
            history.replaceState(null, "", location.pathname + urlParams);
          }
        });
    }

    this.$Progress.finish();
    // НЕ УДАЛЯТЬ
    // Сохраняем в localStorage данные из .env
    // чтобы не потерять на клиентской стороне
    localStorage.setItem("_env", JSON.stringify(this.$store.state._env));
  },
  methods: {
    redirectToLogin() {
      if (this.$route.name !== "home") {
        let cachedRoute = {
          name: this.$route.name,
          params: this.$route.params,
          hash: this.$route.hash,
          query: this.$route.query,
        };
        this.$router
          .push({
            name: "home",
          })
          .then(() => {
            this.$store.state._modals.push({
              component: LoginModal,
              options: {
                routeAfterLogin: cachedRoute,
              },
            });
          });
      } else {
        this.$store.state._modals.push({
          component: LoginModal,
        });
      }
    },
  },
  metaInfo() {
    return this.$seo();
  },
  components: {
    ModalComponent,
    FooterComponent,
    HeaderComponent,
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
