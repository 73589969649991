<template>
  <figure class="icon close-icon">
    <svg fill="none" height="44" viewBox="0 0 44 44" width="44" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6666 14.667L29.3333 29.3337"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M29.3333 14.667L14.6666 29.3337"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CloseIcon",
};
</script>
