<template>
  <figure class="icon">
    <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
      <path
        clip-rule="evenodd"
        d="M11 9.6V12.4H14.9606C14.3824 14.0296 12.8256 15.2 11 15.2C8.6844 15.2 6.8 13.3156 6.8 11C6.8 8.6844 8.6844 6.8 11 6.8C12.0038 6.8 12.9698 7.1598 13.7202 7.8136L15.5598 5.7024C14.2998 4.6048 12.6814 4 11 4C7.1402 4 4 7.1402 4 11C4 14.8598 7.1402 18 11 18C14.8598 18 18 14.8598 18 11V9.6H11Z"
        fill="black"
        fill-rule="evenodd"
      />
      <mask id="mask0" height="14" mask-type="alpha" maskUnits="userSpaceOnUse" width="14" x="4" y="4">
        <path
          clip-rule="evenodd"
          d="M11 9.6V12.4H14.9606C14.3824 14.0296 12.8256 15.2 11 15.2C8.6844 15.2 6.8 13.3156 6.8 11C6.8 8.6844 8.6844 6.8 11 6.8C12.0038 6.8 12.9698 7.1598 13.7202 7.8136L15.5598 5.7024C14.2998 4.6048 12.6814 4 11 4C7.1402 4 4 7.1402 4 11C4 14.8598 7.1402 18 11 18C14.8598 18 18 14.8598 18 11V9.6H11Z"
          fill="white"
          fill-rule="evenodd"
        />
      </mask>
      <g mask="url(#mask0)"></g>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "GOIcon",
};
</script>
