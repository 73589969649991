<template>
  <figure class="icon">
    <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
      <path
        clip-rule="evenodd"
        d="M12.4986 19V11.7022H14.995L15.3695 8.85725H12.4986V7.04118C12.4986 6.21776 12.7308 5.65661 13.936 5.65661L15.4706 5.65599V3.11138C15.2052 3.07756 14.2942 3 13.2339 3C11.0198 3 9.50399 4.32557 9.50399 6.75942V8.85725H7V11.7022H9.50399V19H12.4986Z"
        fill="black"
        fill-rule="evenodd"
      />
      <mask id="mask0" height="16" mask-type="alpha" maskUnits="userSpaceOnUse" width="9" x="7" y="3">
        <path
          clip-rule="evenodd"
          d="M12.4986 19V11.7022H14.995L15.3695 8.85725H12.4986V7.04118C12.4986 6.21776 12.7308 5.65661 13.936 5.65661L15.4706 5.65599V3.11138C15.2052 3.07756 14.2942 3 13.2339 3C11.0198 3 9.50399 4.32557 9.50399 6.75942V8.85725H7V11.7022H9.50399V19H12.4986Z"
          fill="white"
          fill-rule="evenodd"
        />
      </mask>
      <g mask="url(#mask0)"></g>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "FBIcon",
};
</script>
