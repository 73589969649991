<template>
  <figure class="icon">
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.9518 9.11743C24.2197 12.3853 24.2197 17.6837 20.9518 20.9516C17.6839 24.2195 12.3856 24.2195 9.11768 20.9516C5.84977 17.6837 5.84977 12.3853 9.11768 9.11743C12.3856 5.84953 17.6839 5.84953 20.9518 9.11743"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M25.3332 25.3334L20.9465 20.9468"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "SearchIcon",
};
</script>
