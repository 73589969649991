<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.70304 4.09499L3.52904 7.52499C2.23104 8.24599 2.23104 10.112 3.52904 10.833L9.70304 14.263C11.131 15.056 12.868 15.056 14.297 14.263L20.471 10.833C21.769 10.112 21.769 8.24599 20.471 7.52499L14.297 4.09499C12.868 3.30199 11.132 3.30199 9.70304 4.09499Z"
        stroke="#323232"
        stroke-width="1.419"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.99109 12.2V16.124C5.99109 17.073 6.46609 17.958 7.25509 18.483L8.86209 19.552C10.7631 20.816 13.2371 20.816 15.1371 19.552L16.7441 18.483C17.5341 17.958 18.0081 17.072 18.0081 16.124V12.2"
        stroke="#323232"
        stroke-width="1.4167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "GraduateIcon",
};
</script>
