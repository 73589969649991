<template>
  <figure class="icon google-icon">
    <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7662 11.2478C21.7662 10.5237 21.7021 9.83616 21.5921 9.16699H11.2337V13.3012H17.1646C16.8987 14.6578 16.1196 15.8037 14.9646 16.5828V19.3328H18.5029C20.5746 17.417 21.7662 14.5937 21.7662 11.2478Z"
        fill="#4285F4"
      />
      <path
        d="M11.2338 22.0004C14.2038 22.0004 16.6879 21.0104 18.5029 19.3329L14.9646 16.5829C13.9746 17.2429 12.7188 17.6463 11.2338 17.6463C8.36459 17.6463 5.93542 15.7121 5.06459 13.0996H1.41625V15.9321C3.22209 19.5254 6.93459 22.0004 11.2338 22.0004Z"
        fill="#34A853"
      />
      <path
        d="M5.06458 13.0992C4.83542 12.4392 4.71625 11.7334 4.71625 11C4.71625 10.2667 4.84458 9.56086 5.06458 8.90086V6.06836H1.41625C0.664582 7.55336 0.233749 9.22169 0.233749 11C0.233749 12.7784 0.664582 14.4467 1.41625 15.9317L5.06458 13.0992Z"
        fill="#FBBC05"
      />
      <path
        d="M11.2338 4.35417C12.8563 4.35417 14.3046 4.91334 15.4504 6.00417L18.5854 2.86917C16.6879 1.09083 14.2038 0 11.2338 0C6.93459 0 3.22209 2.475 1.41625 6.06834L5.06459 8.90084C5.93542 6.28834 8.36459 4.35417 11.2338 4.35417Z"
        fill="#EA4335"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "GoogleIcon",
};
</script>
