<template>
  <transition-group v-show="$store.state._modals.length" class="modal" name="bounce" tag="div">
    <div v-for="(modal, index) in modals" :key="JSON.stringify(modal) + index" class="modal__content">
      <div ref="background" class="modal__background" @click="close(index)"></div>
      <div
        ref="modal"
        class="modal__body"
        :class="{ 'modal__body--bottom': modal.alignBottom, 'modal__body--top': modal.alignTop }"
      >
        <component
          :is="modal.component"
          :key="index"
          v-bind="modal.options"
          @close="close(index)"
        ></component>
      </div>
    </div>
  </transition-group>
</template>

<script>
export default {
  name: "ModalComponent",
  methods: {
    close(i) {
      return this.$store.state._modals.splice(i, 1);
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.close(this.$store.state._modals.length - 1);
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener("keydown", () => {});
  },
  computed: {
    modals() {
      return this.$store.state._modals;
    },
  },
  updated() {
    if (this.$refs.modal && this.$refs.modal.length) {
      setTimeout(() => {
        this.$refs.modal.forEach((m, i) => {
          const bounds = m.getBoundingClientRect();
          this.$refs.background[i].style = `width:${bounds.width}px`;
        });
      }, 300);
    }
  },
  watch: {
    "$store.state._modals.length"(val) {
      if (val > 0) {
        document.body.classList.add("no-overflow");
      } else {
        document.body.classList.remove("no-overflow");
      }
    },
    "$route.name"() {
      this.$store.state._modals = [];
    },
    "$route.params"() {
      this.$store.state._modals = [];
    },
  },
};
</script>

<style lang="stylus">
@keyframes bounce-in {
  0% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.bounce-enter-active {
  animation: bounce-in var(--transition) cubic-bezier(0.175, 0.885, 0.320, 1.275);
}

.bounce-enter {
  animation: bounce-in var(--transition) reverse cubic-bezier(0.175, 0.885, 0.320, 1.275);
}

.no-overflow {
  overflow hidden
}

.modal {
  fixed left top
  width 100%
  height 100%
  z-index 999
  overflow hidden
  background: var(--black_o2);

  &__content {
    width 100%
    height 100%
    position relative
    display none
    align-items flex-start
    justify-content center
    overflow-y auto

    &:last-child {
      display flex
    }
  }

  &__background {
    fixed left top
    height 100%
    z-index 0
    cursor pointer
  }

  &__body {
    display flex
    justify-content: center;
    width 100%
    margin: 80px 0;
    +below(590px) {
      margin 0
    }

    &--top {
      margin-top: 0;
      margin-bottom: auto;
    }

    &--bottom {
      margin-top: auto;
      margin-bottom: 0;
    }
  }
}

.modal-body {
  background: var(--white);
  border-radius: 15px;
  padding 50px
  display flex
  flex-direction column
  align-items stretch
  justify-content stretch
  color var(--black)
  justify-self center
  align-self center
  max-width: 588px;
  width 100%
  z-index 2
  box-shadow: 0 16px 24px var(--black_o06), 0px 2px 6px var(--black_o04), 0px 0px 1px var(--black_o04);
  +below(590px) {
    padding 16px
    border-radius 0
  }

  &__header {
    display flex
    flex-direction column
    position relative
    margin-bottom 40px
  }

  &__footer {
    margin-top 30px
    border-top 1px solid var(--border_color)
    padding-top 30px
    display flex
    flex-direction column
    gap 15px

    .btn-gray-tr {
      border-width 1px
      font-weight normal
      gap 10px
      align-items center

      .icon {
        display flex
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 2.25em;
    line-height: 44px;
    color: var(--black);
    margin-bottom 10px

    +below(590px) {
      font-size 1.9em
    }
  }

  &__subtitle {
    font-weight: normal;
    font-size: 1em;
    line-height: 140%;
    color: var(--black);
    display flex
    flex-direction column
  }

  &__text {
    font-weight: normal;
    font-size: 1em;
    line-height: 140%;
    color: var(--black);
    margin 0

    a {
      text-decoration underline var(--black)
    }

    &--light a {
      text-decoration underline var(--gray_divider)
    }
  }

  &__close {
    absolute right top
    background none
    padding 0
    border none
    cursor pointer
    display flex

    .icon {
      width 44px
      height 44px

      svg {
        width 100%
        height 100%
      }
    }
  }

  &__row {
    display flex
    align-items center
    justify-content space-between
    gap 10px
    +below(590px) {
      flex-wrap wrap
    }
  }

  &__form {
    display flex
    flex-direction column
    gap 10px

    .btn {
      margin-top: 20px;
    }

    .input__container,
    .btn {
      width 100%
    }
  }
}
</style>
