import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions/index";
import getters from "./getters/index";
import mutations from "./mutations";

Vue.use(Vuex);
export default function createStore(env) {
  return new Vuex.Store({
    actions: actions,
    mutations: mutations,
    getters: getters,
    state() {
      let stateData = {
        _modals: [],
        _status: {},
        _types: {
          CODE_ADDITIONALS_FOR_WHOM: 1,
          CODE_ADDITIONALS_WHAT_LEARN: 2,
          CODE_ADDITIONALS_REQUIREMENTS: 3,
          CODE_ADDITIONALS_SKILLS: 4,
          CODE_COURSE_EASY: 1,
          CODE_COURSE_MEDIUM: 2,
          CODE_COURSE_HARD: 3,
          CODE_TEST_TEXT_INPUT: 1,
          CODE_TEST_SELECTING_ONE: 2,
          CODE_TEST_SELECTING_FEW: 3,
          CODE_TEST_FILE_UPLOAD: 4,
        },
        _loading_types: {
          DEFAULT: 1,
          PAGINATION: 2,
          LOAD_MORE: 3,
        },
        months: [
          {
            id: "01",
            title: "Январь",
          },
          {
            id: "02",
            title: "Февраль",
          },
          {
            id: "03",
            title: "Март",
          },
          {
            id: "04",
            title: "Апрель",
          },
          {
            id: "05",
            title: "Май",
          },
          {
            id: "06",
            title: "Июнь",
          },
          {
            id: "07",
            title: "Июль",
          },
          {
            id: "08",
            title: "Август",
          },
          {
            id: "09",
            title: "Сентябрь",
          },
          {
            id: "10",
            title: "Октябрь",
          },
          {
            id: "11",
            title: "Ноябрь",
          },
          {
            id: "12",
            title: "Декабрь",
          },
        ],
        courses_category_page: {
          courses_min_max_price: {
            min: 0,
            max: 0,
          },
          types: [],
          categories: [],
          category: {},
          courses: {},
        },
        courses_page: {
          courses_count: 0,
          courses_min_max_price: {
            min: 0,
            max: 0,
          },
          types: [],
          categories: [],
          popular_courses: {},
          favorite_courses: {},
          trend_courses: {},
        },
        course_page: {
          course: {},
          similar_courses: [],
        },
        school_page: {
          school: {},
        },
        home_page: {
          counts: {
            users_count: 0,
            lessons_count: 0,
            teachers_count: 0,
          },
          courses: [],
          categories: [],
          faqs: [],
        },
        schools_page: {
          schools_count: 0,
          schools: {
            data: [],
          },
        },
        auth: {
          user: null,
        },
        tmp: {},
      };
      // ХАК ЧТОБЫ НЕ ТЕРЯТЬ .env НА КЛИЕНТСКОЙ СТОРОНЕ
      // Если есть .env - значит мы на серверной стороне
      // сохраняем переменные в $store
      if (env) {
        return Object.assign(stateData, {
          _env: {
            MEDIA_ENDPOINT: env.MEDIA_ENDPOINT,
            APOLLO_ENDPOINT: env.APOLLO_ENDPOINT,
          },
        });
      }
      // Нет env! значит надо здесь вставить данные которые
      // ранее были сохранены в localStorage в App.vue mounted() хуке
      return Object.assign(stateData, {
        _env: JSON.parse(localStorage.getItem("_env") || "{}"),
      });
    },
  });
}
