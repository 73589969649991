<template>
  <figure class="icon eye-icon">
    <svg fill="none" height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7104 10.2914C15.9302 11.5112 15.9302 13.4914 14.7104 14.7133C13.4906 15.9331 11.5104 15.9331 10.2886 14.7133C9.06877 13.4935 9.06877 11.5133 10.2886 10.2914C11.5104 9.06953 13.4896 9.06953 14.7104 10.2914Z"
        stroke="#8B8B8B"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M3.125 12.4997C3.125 11.8132 3.28333 11.1341 3.58958 10.508C5.16771 7.28197 8.65521 5.20801 12.5 5.20801C16.3448 5.20801 19.8323 7.28197 21.4104 10.508C21.7167 11.1341 21.875 11.8132 21.875 12.4997C21.875 13.1861 21.7167 13.8653 21.4104 14.4913C19.8323 17.7174 16.3448 19.7913 12.5 19.7913C8.65521 19.7913 5.16771 17.7174 3.58958 14.4913C3.28333 13.8653 3.125 13.1861 3.125 12.4997Z"
        stroke="#8B8B8B"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "EyeIcon",
};
</script>
