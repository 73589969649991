// [title, keywords, description, ogTitle, ogImage, ogDescription]
const common = ["Etikaego", "", "", "Etikaego", "", ""];

export default {
  install(Vue) {
    Vue.prototype.$seo = function $seo(page, title, keywords, description, ogTitle, ogImage, ogDescription) {
      const list = {
        custom: [title, keywords, description, ogTitle, ogImage, ogDescription],
        common: common,
      };
      const item = list[page] || list.common;
      const meta = {};

      if (title && item[0]) {
        meta.title = item[0].replace("s%", title);
      } else {
        meta.title = title || item[0];
      }

      if (keywords && item[1]) {
        meta.keywords = item[1].replace("s%", keywords);
      } else {
        meta.keywords = keywords || item[1];
      }

      if (description && item[2]) {
        meta.description = item[2].replace("s%", description);
      } else {
        meta.description = description || item[2];
      }
      if (ogTitle && item[3]) {
        meta.og_title = item[3].replace("s%", ogTitle);
      } else {
        meta.og_title = ogTitle || item[3];
      }

      if (ogImage && item[4]) {
        meta.og_image = item[4].replace("s%", ogImage);
      } else {
        meta.og_image = ogImage || item[4];
      }

      if (ogDescription && item[5]) {
        meta.og_description = item[5].replace("s%", ogDescription);
      } else {
        meta.og_description = ogDescription || item[5];
      }

      return {
        title: meta.title,
        meta: [
          { vmid: "og:title", name: "og:title", content: meta.og_title },
          { vmid: "og:image", name: "og:image", content: meta.og_image },
          { vmid: "og:description", name: "og:description", content: meta.og_description },
          { vmid: "keywords", name: "keywords", content: meta.keywords },
          { vmid: "description", name: "description", content: meta.description },
        ],
      };
    };
  },
};
