<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 7H3C2.448 7 2 6.552 2 6V4C2 3.448 2.448 3 3 3H21C21.552 3 22 3.448 22 4V6C22 6.552 21.552 7 21 7Z"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M9 11H15" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M20.667 7V19C20.667 20.105 19.772 21 18.667 21H5.33301C4.22801 21 3.33301 20.105 3.33301 19V7"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "ArchiveIcon",
};
</script>
