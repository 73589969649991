import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "/schools",
      name: "schools",
      component: () => import("./views/schools/list/index.vue"),
    },
    {
      path: "/schools/:link",
      name: "school",
      component: () => import("./views/schools/open/index.vue"),
      meta: {
        parent: [
          {
            title: "Все школы",
            name: "schools",
          },
        ],
      },
    },
    {
      path: "/courses",
      name: "courses",
      component: () => import("./views/courses/list/index.vue"),
    },
    {
      path: "/courses/:link",
      name: "course_category",
      component: () => import("./views/courses/category/index.vue"),
      meta: {
        parent: [
          {
            title: "Все курсы",
            name: "courses",
          },
        ],
      },
    },
    {
      path: "/courses/:category/:link",
      name: "course",
      component: () => import("./views/courses/open/index.vue"),
      meta: {
        parent: [
          {
            title: "Все курсы",
            name: "courses",
          },
        ],
      },
    },
    {
      path: "/account",
      name: "account",
      component: () => import("./views/auth/account/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/account/education",
      name: "account_education",
      component: () => import("./views/auth/education/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/account/education/list/:id",
      name: "account_education_list",
      component: () => import("./views/auth/education/list/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/account/education/open/:module/:lesson",
      name: "account_education_open",
      component: () => import("./views/auth/education/open/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/account/completed",
      name: "account_completed",
      component: () => import("./views/auth/completed/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/term-conditions",
      name: "term_conditions",
      component: () => import("./views/PersonalData.vue"),
    },
    {
      path: "/privacy-policy",
      name: "privacy_policy",
      component: () => import("./views/PrivacyPolicy.vue"),
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
