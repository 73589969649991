<template>
  <div class="modal-body">
    <div class="modal-body__header">
      <h1 class="modal-body__title">Восстановление пароля</h1>
      <div class="modal-body__subtitle">
        <span>Введите e-mail, который вы используете на Al coursi.</span>
        <span>И придумайте новый пароль</span>
      </div>
      <button class="modal-body__close" type="button" @click="$emit('close')">
        <CloseIcon />
      </button>
    </div>
    <form class="modal-body__form" @submit.prevent="submit">
      <InputComponent
        v-model.trim="form.email.value"
        :errors="form.email.errors"
        autofocus
        title="E-mail"
        type="email"
      />
      <InputComponent
        v-model.trim="form.password.value"
        :errors="form.password.errors"
        :type="form.password.show ? 'text' : 'password'"
        title="Новый пароль"
        @submit="form.password.show = !form.password.show"
      >
        <template v-slot:action="">
          <EyeCloseIcon v-if="form.password.show" />
          <EyeIcon v-else />
        </template>
      </InputComponent>
      <InputComponent
        v-model.trim="form.password_confirmation.value"
        :errors="form.password_confirmation.errors"
        :type="form.password_confirmation.show ? 'text' : 'password'"
        title="Повторите пароль"
        @submit="form.password_confirmation.show = !form.password_confirmation.show"
      >
        <template v-slot:action="">
          <EyeCloseIcon v-if="form.password.show" />
          <EyeIcon v-else />
        </template>
      </InputComponent>
      <button class="btn btn--main btn--medium" type="submit">
        <LoadingIndicator v-show="loading" />
        <span v-show="!loading">Войти</span>
      </button>
      <p class="modal-body__text modal-body__text--light">
        <span>Вернуться к </span>
        <a @click.prevent="showLoginModal">авторизации</a>
      </p>
    </form>
    <div class="modal-body__footer" v-if="false">
      <button class="btn btn--light btn--medium" type="button">
        <span>Войти с помощью Google</span>
        <GoogleIcon />
      </button>
      <button class="btn btn--light btn--medium" type="button">
        <span>Войти с помощью Facebook</span>
        <FacebookIcon />
      </button>
    </div>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import EyeIcon from "components/svg/EyeIcon.vue";
import GoogleIcon from "components/svg/GoogleIcon.vue";
import FacebookIcon from "components/svg/FacebookIcon.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import EyeCloseIcon from "components/svg/EyeCloseIcon.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import RESET_PASSWORD from "gql/auth/ResetPassword.graphql";

export default {
  name: "LoginModalComponent",
  props: {
    token: String,
  },
  data() {
    return {
      loading: false,
      form: {
        email: {
          errors: [],
          value: null,
        },
        password: {
          show: false,
          errors: [],
          value: null,
        },
        password_confirmation: {
          show: false,
          errors: [],
          value: null,
        },
      },
    };
  },
  components: {
    LoadingIndicator,
    EyeCloseIcon,
    FacebookIcon,
    GoogleIcon,
    EyeIcon,
    InputComponent,
    CloseIcon,
  },
  methods: {
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: RESET_PASSWORD,
            variables: {
              token: this.token,
              email: this.form.email.value,
              password: this.form.password.value,
              password_confirmation: this.form.password_confirmation.value,
            },
          })
          .then(({ data }) => {
            if (data && data.ResetPassword) {
              this.$notify({
                title: "Готово",
                text: data.ResetPassword.message,
                duration: 5000,
                speed: 200,
                type: "success",
              });
              this.$emit("close");
              this.showLoginModal();
            }
            this.loading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors = err.extensions.validation[key];
            }
          });
        }
      });
    },
    showLoginModal() {
      this.$store.state._modals = [
        {
          component: LoginModal,
        },
      ];
    },
  },
};
</script>
