<template>
  <div class="modal-body message-modal">
    <div class="message-modal__title">{{ title }}</div>
    <div class="message-modal__text" v-html="text"></div>
    <button type="button" class="btn btn--main btn--small" @click="handleClick">Закрыть</button>
  </div>
</template>
<script>
export default {
  name: "MessageModal",
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
    text: {
      type: String,
      default() {
        return "";
      },
    },
  },
  methods: {
    handleClick() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="stylus">
.message-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  &__title {
    font-weight: bold;
    font-size: 2.25em;
    line-height: 44px;
    color: var(--black);
  }

  &__text {
    font-weight: normal;
    font-size: 1em;
    line-height: 140%;
    color: var(--black);
    margin: 10px 0 30px;
  }
}
</style>
