<template>
  <div class="modal-body">
    <div class="modal-body__header">
      <h1 class="modal-body__title">Забыли пароль?</h1>
      <div class="modal-body__subtitle">
        <span>Введите e-mail, который вы используете на Al coursi.</span>
        <span>Мы вышлем на него ссылку на восстановления пароля</span>
      </div>
      <button class="modal-body__close" type="button" @click="$emit('close')">
        <CloseIcon />
      </button>
    </div>
    <form class="modal-body__form" @submit.prevent="submit">
      <InputComponent
        v-model.trim="form.email.value"
        :errors="form.email.errors"
        autofocus
        title="E-mail"
        type="email"
      />
      <button class="btn btn--main btn--medium" type="submit">
        <LoadingIndicator v-show="loading" />
        <span v-show="!loading">Восстановить пароль</span>
      </button>
      <p class="modal-body__text modal-body__text--light">
        <span>Вернуться к </span>
        <a @click.prevent="showLoginModal">авторизации</a>
      </p>
    </form>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import FORGOT_PASSWORD from "gql/auth/ForgotPassword.graphql";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "LoginModalComponent",
  data() {
    return {
      loading: false,
      form: {
        email: {
          errors: [],
          value: null,
        },
      },
    };
  },
  methods: {
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: FORGOT_PASSWORD,
            variables: {
              email: this.form.email.value,
            },
          })
          .then(({ data }) => {
            if (data && data.ForgotPassword) {
              this.$notify({
                title: "Готово",
                text: data.ForgotPassword.message,
                duration: 5000,
                speed: 200,
                type: "success",
              });
              this.$emit("close");
            }
            this.loading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors = err.extensions.validation[key];
            }
          });
        }
      });
    },
    showLoginModal() {
      this.$emit("close");
    },
  },
  components: { LoadingIndicator, InputComponent, CloseIcon },
};
</script>
