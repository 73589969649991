import SchoolsPage from "@/graphql/pages/SchoolsPage.graphql";
import CoursesPage from "@/graphql/pages/CoursesPage.graphql";
import CoursesCategoryPage from "@/graphql/pages/CoursesCategoryPage.graphql";

async function SCHOOLS_PAGE({ state }, { apollo, variables, loadingType }) {
  await apollo
    .query({
      query: SchoolsPage,
      variables: variables,
    })
    .then(({ data }) => {
      state.schools_page.schools_count = data.schools_count;
      switch (loadingType) {
        case state._loading_types.DEFAULT:
        case state._loading_types.PAGINATION:
          state.schools_page.schools = data.schools_paginate;
          break;
        case state._loading_types.LOAD_MORE:
          {
            state.schools_page.schools_count = data.schools_count;
            state.schools_page.schools.paginatorInfo = data.schools_paginate.paginatorInfo;
            state.schools_page.schools.data.push(...data.schools_paginate.data);
          }
          break;
      }
    })
    .catch(() => {});
}

async function COURSES_PAGE({ state }, { apollo, variables, loadingType }) {
  await apollo
    .query({
      query: CoursesPage,
      variables: variables,
    })
    .then(({ data }) => {
      state.courses_page.courses_count = data.courses_count;
      state.courses_page.courses_min_max_price = data.courses_min_max_price;
      state.courses_page.categories = data.categories;
      state.courses_page.types = data.types;

      switch (loadingType) {
        case state._loading_types.DEFAULT:
        case state._loading_types.PAGINATION:
          {
            state.courses_page.popular_courses = data.popular_courses;
            state.courses_page.favorite_courses = data.favorite_courses;
            state.courses_page.trend_courses = data.trend_courses;
          }
          break;
        case state._loading_types.LOAD_MORE:
          {
            state.courses_page.popular_courses.paginatorInfo = data.popular_courses.paginatorInfo;
            state.courses_page.popular_courses.data.push(...data.popular_courses.data);

            state.courses_page.favorite_courses.paginatorInfo = data.favorite_courses.paginatorInfo;
            state.courses_page.favorite_courses.data.push(...data.favorite_courses.data);

            state.courses_page.trend_courses.paginatorInfo = data.trend_courses.paginatorInfo;
            state.courses_page.trend_courses.data.push(...data.trend_courses.data);
          }
          break;
      }
    })
    .catch(() => {});
}

async function COURSES_CATEGORY_PAGE({ state }, { apollo, variables, loadingType }) {
  await apollo
    .query({
      query: CoursesCategoryPage,
      variables: variables,
    })
    .then(({ data }) => {
      state.courses_category_page.courses_min_max_price = data.courses_min_max_price;
      state.courses_category_page.categories = data.categories;
      state.courses_category_page.types = data.types;
      state.courses_category_page.category = data.category;

      switch (loadingType) {
        case state._loading_types.DEFAULT:
        case state._loading_types.PAGINATION:
          {
            state.courses_category_page.courses = data.courses_paginate;
          }
          break;
        case state._loading_types.LOAD_MORE:
          {
            state.courses_category_page.courses.paginatorInfo = data.courses_paginate.paginatorInfo;
            state.courses_category_page.courses.data.push(...data.courses_paginate.data);
          }
          break;
      }
    })
    .catch(() => {});
}

export { SCHOOLS_PAGE, COURSES_PAGE, COURSES_CATEGORY_PAGE };
