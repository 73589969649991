<template>
  <figure class="icon facebook-icon">
    <svg fill="none" height="22" viewBox="0 0 23 22" width="23" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 21.9998C17.5751 21.9998 22.4999 17.075 22.4999 10.9999C22.4999 4.92483 17.5751 0 11.5 0C5.42492 0 0.500092 4.92483 0.500092 10.9999C0.500092 17.075 5.42492 21.9998 11.5 21.9998Z"
        fill="#1977F3"
      />
      <path
        d="M15.7817 14.1804L16.269 11.0001H13.2185V8.93653C13.2185 8.06719 13.6439 7.21795 15.0114 7.21795H16.3989V4.51091C16.3989 4.51091 15.1398 4.2959 13.9363 4.2959C11.4242 4.2959 9.78136 5.81803 9.78136 8.57611V11.0001H6.98768V14.1804H9.78136V21.8669C10.3413 21.9551 10.9152 21.9999 11.4999 21.9999C12.0847 21.9999 12.6586 21.9535 13.2185 21.8669V14.1804H15.7817Z"
        fill="white"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "FacebookIcon",
};
</script>
