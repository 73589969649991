<template>
  <footer class="footer">
    <div class="container footer__container">
      <ul class="footer-nav">
        <li class="footer-nav__col">
          <h4 class="footer-nav__title">Store</h4>
          <ul class="footer-nav__links">
            <li>
              <a class="footer-nav__link" href="#"> Catalog </a>
            </li>
            <li>
              <a class="footer-nav__link" href="#"> Popular </a>
            </li>
            <li>
              <a class="footer-nav__link" href="#"> Features </a>
            </li>
          </ul>
        </li>
        <li class="footer-nav__col">
          <h4 class="footer-nav__title">About</h4>
          <ul class="footer-nav__links">
            <li>
              <a class="footer-nav__link" href="#"> Catalog </a>
            </li>
            <li>
              <a class="footer-nav__link" href="#"> Popular </a>
            </li>
            <li>
              <a class="footer-nav__link" href="#"> Features </a>
            </li>
          </ul>
        </li>
        <li class="footer-nav__col">
          <h4 class="footer-nav__title">Catalog</h4>
          <ul class="footer-nav__links">
            <li>
              <a class="footer-nav__link" href="#"> Catalog </a>
            </li>
            <li>
              <a class="footer-nav__link" href="#"> Popular </a>
            </li>
            <li>
              <a class="footer-nav__link" href="#"> Features </a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="subscribe">
        <h4 class="footer-nav__title">Подписаться на новости</h4>
        <div class="subscribe__text">
          Подпишитесь, чтобы быть в курсе новостей и последних обновлений. Только самое лучшее для тебя. Без
          мусора, без спама.
        </div>
        <form class="subscribe__form" @submit.prevent>
          <InputComponent
            v-model.trim="form.email.value"
            :errors="form.email.errors"
            title="Введите Email"
            type="email"
          />
          <button class="btn btn--main btn--medium" type="submit">Отправить</button>
        </form>
      </div>
    </div>
    <div class="container footer__bottom-container">
      <div class="footer__bottom">
        <div class="footer__copyright">© {{ currentYear }}. All rights reserved</div>
        <ul class="footer-socials">
          <li class="footer-socials__item">
            <a href="#">
              <TWIcon />
            </a>
          </li>
          <li class="footer-socials__item">
            <a href="#">
              <FBIcon />
            </a>
          </li>
          <li class="footer-socials__item">
            <a href="#">
              <GOIcon />
            </a>
          </li>
          <li class="footer-socials__item">
            <a href="#">
              <INIcon />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from "moment";
import TWIcon from "components/svg/TWIcon.vue";
import FBIcon from "components/svg/FBIcon.vue";
import GOIcon from "components/svg/GOIcon.vue";
import INIcon from "components/svg/INIcon.vue";
import InputComponent from "components/inputs/InputComponent.vue";

export default {
  name: "FooterComponent",
  data() {
    return {
      form: {
        email: {
          value: null,
          errors: [],
        },
      },
    };
  },
  computed: {
    currentYear() {
      return moment().format("YYYY");
    },
  },
  components: {
    InputComponent,
    TWIcon,
    FBIcon,
    GOIcon,
    INIcon,
  },
};
</script>

<style lang="stylus">
.footer {
  margin-top: auto;
  padding 0 32px
  border-top 1px solid var(--border_color)
  background var(--white)
  +below(640px) {
    padding 0 20px
  }

  &__container {
    padding 60px 0
    box-sizing border-box
    display grid
    grid-gap 30px
    grid-template-columns 8fr 4fr
    +below(1196px) {
      grid-template-columns 7fr 5fr
    }
    +below(1024px) {
      grid-template-columns 6fr 6fr
    }
    +below(940px) {
      grid-template-columns 1fr
    }
    +below(640px) {
      padding 30px 0
    }
  }

  &__bottom {
    display flex
    align-items center
    justify-content space-between
    gap 20px
    +below(640px) {
      flex-direction column-reverse
      align-items flex-start
    }

    &-container {
      padding 50px 0
      +below(640px) {
        padding 30px 0
      }
    }
  }

  &__copyright {
    font-size: 0.75em
    line-height: 18px;
  }
}


.footer-nav {
  display flex
  gap 30px
  align-items flex-start
  +below(500px) {
    flex-wrap wrap
    gap 40px
  }

  &__title {
    font-weight: bold;
    font-size: 1.125em;
    line-height: 32px;
    color: var(--black);
    margin 0
  }

  &__col {
    max-width 165px
    width 100%
    display grid
    grid-gap 24px
    align-items start
    +below(500px) {
      max-width initial
      width auto
    }
  }

  &__links {
    display grid
    grid-gap 12px
    align-items start
  }

  &__link {
    font-size: 1.25em
    line-height: 34px;
  }
}


.footer-socials {
  display flex
  align-items center
  margin -12px

  &__item {
    display flex

    a {
      padding 12px
      display flex
      align-items center
    }
  }
}

.subscribe {
  display grid
  grid-gap 16px

  &__text {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color var(--black)
  }

  &__form {
    display flex
    width 100%
    gap 15px
    +below(420px) {
      flex-direction column
      justify-content stretch
      align-items stretch
    }

    .input {
      height 64px
      &__container {
        width 100%
      }
    }

    .btn {
      width 170px
      +below(420px) {
        width 100%
      }
    }
  }
}
</style>
