<template>
  <figure class="icon">
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 10L12 14L16 10"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "DownIconSmall",
};
</script>
