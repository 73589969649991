<template>
  <div class="modal-body">
    <div class="modal-body__header">
      <h1 class="modal-body__title">Регистрация школы</h1>
      <span class="modal-body__subtitle">Для регистрации заполните все поля формы</span>
      <button class="modal-body__close" type="button" @click="$emit('close')">
        <CloseIcon />
      </button>
    </div>
    <form class="modal-body__form" @submit.prevent="submit">
      <InputComponent v-model.trim="form.title.value" :errors="form.title.errors" title="Название школы" />
      <div class="modal-body__row">
        <InputComponent v-model.trim="form.name.value" :errors="form.name.errors" autofocus title="Имя" />
        <InputComponent v-model.trim="form.surname.value" :errors="form.surname.errors" title="Фамилия" />
      </div>
      <InputComponent
        v-model.trim="form.email.value"
        :errors="form.email.errors"
        title="E-mail"
        type="email"
      />
      <InputComponent
        v-model.trim="form.password.value"
        :errors="form.password.errors"
        :messages="form.password.messages"
        :type="form.password.show ? 'text' : 'password'"
        title="Пароль"
        @submit="form.password.show = !form.password.show"
      >
        <template v-slot:action="">
          <EyeCloseIcon v-if="form.password.show" />
          <EyeIcon v-else />
        </template>
      </InputComponent>
      <InputComponent
        v-model.trim="form.password_confirmation.value"
        :errors="form.password_confirmation.errors"
        :messages="form.password_confirmation.messages"
        :type="form.password_confirmation.show ? 'text' : 'password'"
        title="Подтвердите пароль"
        @submit="form.password_confirmation.show = !form.password_confirmation.show"
      >
        <template v-slot:action="">
          <EyeCloseIcon v-if="form.password_confirmation.show" />
          <EyeIcon v-else />
        </template>
      </InputComponent>
      <button class="btn btn--main btn--medium" type="submit">
        <LoadingIndicator v-show="loading" />
        <span v-show="!loading">Зарегистрировать</span>
      </button>
      <p class="modal-body__text modal-body__text--light">
        <span>Уже зарегистрированы? </span>
        <a @click.prevent="showLoginModal">Войти</a>
      </p>
    </form>
    <div class="modal-body__footer" v-if="false">
      <button class="btn btn--light btn--medium" type="button">
        <span>Регистрация с помощью Google</span>
        <GoogleIcon />
      </button>
      <button class="btn btn--light btn--medium" type="button">
        <span>Регистрация с помощью Facebook</span>
        <FacebookIcon />
      </button>
      <p class="modal-body__text">
        <span>Регистрируясь, я принимаю </span>
        <a @click="showPolicyModal">Политику конфиденциальности</a>
        <span> и </span>
        <a @click="showAgreementModal">Пользовательское соглашение</a>
        <span> Al coursi</span>
      </p>
    </div>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import EyeCloseIcon from "components/svg/EyeCloseIcon.vue";
import EyeIcon from "components/svg/EyeIcon.vue";
import GoogleIcon from "components/svg/GoogleIcon.vue";
import FacebookIcon from "components/svg/FacebookIcon.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import REGISTER_SCHOOL from "gql/auth/RegisterSchool.graphql";
import MessageModal from "./MessageModal.vue";

export default {
  name: "RegisterModalComponent",
  data() {
    return {
      loading: false,
      form: {
        name: {
          errors: [],
          value: null,
        },
        surname: {
          errors: [],
          value: null,
        },
        email: {
          errors: [],
          value: null,
        },
        password: {
          show: false,
          errors: [],
          messages: ["Пароль должен содержать минимум 8 символов"],
          value: null,
        },
        password_confirmation: {
          show: false,
          errors: [],
          messages: ["Пароли должны совпадать"],
          value: null,
        },
        title: {
          errors: [],
          value: null,
        },
      },
    };
  },
  components: {
    LoadingIndicator,
    EyeCloseIcon,
    FacebookIcon,
    GoogleIcon,
    EyeIcon,
    InputComponent,
    CloseIcon,
  },
  methods: {
    showPolicyModal() {
      this.$store.state._modals.push({
        component: MessageModal,
        options: {
          title: "Политика конфиденциальности",
          text: "",
        },
      });
    },
    showAgreementModal() {
      this.$store.state._modals.push({
        component: MessageModal,
        options: {
          title: "Пользовательское соглашение",
          text: "",
        },
      });
    },
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: REGISTER_SCHOOL,
            variables: {
              title: this.form.title.value,
              email: this.form.email.value,
              name: this.form.name.value,
              surname: this.form.surname.value,
              password: this.form.password.value,
              password_confirmation: this.form.password_confirmation.value,
            },
          })
          .then(({ data }) => {
            if (data && data.RegisterSchool) {
              this.$notify({
                title: "Готово",
                text: data.RegisterSchool.message,
                duration: 5000,
                speed: 200,
                type: "success",
              });
              this.$emit("close");
            }
            this.loading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors = err.extensions.validation[key];
            }
          });
        }
      });
    },
    showLoginModal() {
      this.$emit("close");
    },
  },
};
</script>
