<template>
  <div class="modal-body">
    <div class="modal-body__header">
      <h1 class="modal-body__title">Вход</h1>
      <span class="modal-body__subtitle">Для авторизации введите свои данные</span>
      <button class="modal-body__close" type="button" @click="$emit('close')">
        <CloseIcon />
      </button>
    </div>
    <form class="modal-body__form" @submit.prevent="submit">
      <InputComponent
        v-model.trim="form.email.value"
        :errors="form.email.errors"
        autofocus
        title="E-mail"
        type="email"
      />
      <InputComponent
        v-model.trim="form.password.value"
        :errors="form.password.errors"
        :type="form.password.show ? 'text' : 'password'"
        title="Пароль"
        @submit="form.password.show = !form.password.show"
      >
        <template v-slot:action="">
          <EyeCloseIcon v-if="form.password.show" />
          <EyeIcon v-else />
        </template>
      </InputComponent>
      <button class="btn btn--main btn--medium" type="submit" :disabled="loading">
        <LoadingIndicator title="Вход" v-show="loading" />
        <span v-show="!loading">Войти</span>
      </button>
      <p class="modal-body__text modal-body__text--light">
        <a @click.prevent="showRestoreModal">Забыли пароль?</a>
      </p>
      <p class="modal-body__text modal-body__text--light">
        <span>В первый раз на Al coursi? </span>
        <a @click.prevent="showRegisterModal">Регистрация</a>
      </p>
      <p class="modal-body__text modal-body__text--light">
        <a href="/admin" target="_blank">Панель администратора школы</a>
      </p>
    </form>
    <div class="modal-body__footer" v-if="false">
      <button class="btn btn--light btn--medium" type="button">
        <span>Войти с помощью Google</span>
        <GoogleIcon />
      </button>
      <button class="btn btn--light btn--medium" type="button">
        <span>Войти с помощью Facebook</span>
        <FacebookIcon />
      </button>
    </div>
  </div>
</template>

<script>
import RegisterModal from "components/modals/components/RegisterModal.vue";
import CloseIcon from "components/svg/CloseIcon.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import EyeIcon from "components/svg/EyeIcon.vue";
import GoogleIcon from "components/svg/GoogleIcon.vue";
import FacebookIcon from "components/svg/FacebookIcon.vue";
import RestorePasswordModal from "components/modals/components/RestorePasswordModal.vue";
import EyeCloseIcon from "components/svg/EyeCloseIcon.vue";
import LOGIN from "gql/auth/Login.graphql";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "LoginModalComponent",
  props: {
    routeAfterLogin: Object,
  },
  data() {
    return {
      loading: false,
      form: {
        email: {
          errors: [],
          value: null,
        },
        password: {
          show: false,
          errors: [],
          value: null,
        },
      },
    };
  },
  components: {
    LoadingIndicator,
    EyeCloseIcon,
    FacebookIcon,
    GoogleIcon,
    EyeIcon,
    InputComponent,
    CloseIcon,
  },
  computed: {
    formFilled() {
      return this.form.email.value && this.form.password.value;
    },
  },
  methods: {
    resetErrors(form = "form") {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].errors = [];
      });
    },
    submit() {
      if (!this.loading && this.formFilled) {
        this.resetErrors();
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: LOGIN,
            variables: {
              email: this.form.email.value,
              password: this.form.password.value,
            },
          })
          .then(({ data }) => {
            if (data && data.Login) {
              this.$store.state.auth.user = data.Login;
              this.$store.state.cookies["apollo-token"] = data.Login.token;
              this.$cookie.set("apollo-token", data.Login.token, {
                SameSite: "strict",
                Expires: new Date(2147483647 * 1000).toUTCString(),
              });
              this.$router
                .push(
                  this.routeAfterLogin || {
                    name: "account",
                  }
                )
                .then(() => {
                  this.loading = false;
                  this.$emit("close");
                })
                .catch(() => {
                  this.loading = false;
                  this.$emit("close");
                });
            }
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors = err.extensions.validation[key];
            }
          });
        }
      });
    },
    showRestoreModal() {
      this.$store.state._modals.push({
        component: RestorePasswordModal,
      });
    },
    showRegisterModal() {
      this.$store.state._modals.push({
        component: RegisterModal,
      });
    },
  },
};
</script>
