<template>
  <header class="header">
    <div class="container" v-click-outside="closeMenu">
      <div class="header__inner">
        <div class="header__left-side">
          <router-link :to="{ name: 'home' }" class="header__logo">
            <LogoIcon />
          </router-link>
          <button class="header__select" :class="{ 'header__select--active': showMenu }" @click="toggleMenu">
            <span>Все курсы</span>
            <DownIconSmall />
          </button>
        </div>
        <template v-if="mounted">
          <div class="header__right-side" v-if="isUserLoggedIn" v-click-outside="closeUserMenu">
            <a class="header__search-btn" @click.prevent="showSearchModal">
              <SearchIcon />
            </a>
            <!--            <a-->
            <!--              class="header__notifications-btn"-->
            <!--              :class="{ 'header__notifications-btn&#45;&#45;unread': haveUnreadNotifications }"-->
            <!--              @click.prevent="toggleNotificationsList"-->
            <!--            >-->
            <!--              <BellIcon />-->
            <!--              <NotificationsListComponent :active="showNotificationsList" />-->
            <!--            </a>-->
            <a
              class="header__user"
              :class="{ 'header__user--active': showUserMenu }"
              @click.prevent="toggleUserMenu"
            >
              <img
                :src="user.head_img | image($store.state._env.MEDIA_ENDPOINT)"
                class="header__user-image"
                :alt="user.head_img | image_alt"
              />
              <span class="header__user-name">{{ user.name }} {{ user.surname }}</span>
              <DownIconSmall class="header__user-icon" />
              <UserMenuComponent :active="showUserMenu" />
            </a>
          </div>
          <div class="header__right-side" v-else>
            <a class="header__search-btn" @click.prevent="showSearchModal">
              <SearchIcon />
            </a>
            <a class="header__login-btn btn btn--main btn--small" @click.prevent="showLoginModal">Войти</a>
          </div>
        </template>
      </div>
      <div class="header__mobile">
        <button class="header__select" :class="{ 'header__select--active': showMenu }" @click="toggleMenu">
          <span>Все курсы</span>
          <DownIconSmall />
        </button>
        <a
          v-if="isUserLoggedIn"
          class="header__user"
          :class="{ 'header__user--active': showUserMenu }"
          @click.prevent="toggleUserMenuAsModal"
        >
          <img
            :src="user.head_img | image($store.state._env.MEDIA_ENDPOINT)"
            class="header__user-image"
            :alt="user.head_img | image_alt"
          />
          <span class="header__user-name">{{ user.name }} {{ user.surname }}</span>
          <DownIconSmall class="header__user-icon" />
        </a>
        <a class="header__mobile-search" v-else @click.prevent="showSearchModal">
          <span>Поиск</span>
          <SearchIcon />
        </a>
      </div>
      <MainMenuComponent :active="showMenu" />
    </div>
  </header>
</template>

<script>
import SearchIcon from "components/svg/SearchIcon.vue";
import DownIconSmall from "components/svg/DownIconSmall.vue";
import LogoIcon from "components/svg/LogoIcon.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import SearchModal from "components/modals/components/SearchModal.vue";
import MainMenuComponent from "./components/MainMenuComponent.vue";
import ClickOutside from "vue-click-outside";
import UserMenuComponent from "components/header/components/UserMenuComponent.vue";

export default {
  name: "HeaderComponent",
  data() {
    return {
      showMenu: false,
      showNotificationsList: false,
      showUserMenu: false,
      haveUnreadNotifications: true,
      mounted: false,
    };
  },
  computed: {
    isUserLoggedIn() {
      return !!this.user;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
    },
    closeUserMenu() {
      this.showUserMenu = false;
      this.showNotificationsList = false;
    },
    toggleMenu() {
      this.showNotificationsList = false;
      this.showUserMenu = false;
      this.showMenu = !this.showMenu;
    },
    toggleUserMenu() {
      this.showNotificationsList = false;
      this.showMenu = false;
      this.showUserMenu = !this.showUserMenu;
    },
    toggleUserMenuAsModal() {
      this.showNotificationsList = false;
      this.showMenu = false;
      this.showUserMenu = false;
      this.$store.state._modals.push({
        component: UserMenuComponent,
        alignBottom: true,
        options: {
          active: true,
          statical: true,
        },
      });
    },
    toggleNotificationsList() {
      this.showUserMenu = false;
      this.showMenu = false;
      this.showNotificationsList = !this.showNotificationsList;
    },
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
      });
    },
    showSearchModal() {
      this.$store.state._modals.push({
        component: SearchModal,
      });
    },
  },
  watch: {
    "$route.name"() {
      this.showUserMenu = false;
      this.showNotificationsList = false;
      this.showMenu = false;
    },
    "$route.params"() {
      this.showUserMenu = false;
      this.showNotificationsList = false;
      this.showMenu = false;
    },
  },
  components: {
    UserMenuComponent,
    LogoIcon,
    MainMenuComponent,
    SearchIcon,
    DownIconSmall,
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.slide-down-enter-active {
  animation height-in var(--transition) forwards
}

.slide-down-enter {
  animation height-in var(--transition) reverse
}

.header {
  padding 0 32px
  background: var(--white);
  border-bottom 1px solid var(--border_color)
  position relative

  +below(640px) {
    padding 0 20px
  }

  &__inner {
    display flex
    align-items center
    justify-content space-between
    padding 20px 0
    +below(830px) {
      padding 15px 0
      & ^[0]__select {
        display none
      }
    }
  }

  &__left-side,
  &__right-side {
    display flex
    align-items center
  }

  &__logo {
    padding: 20px;
    margin: -20px;
    margin-right: 30px;
    +below(830px) {
      margin-right: 0;
    }

    .icon {
      width 102px
      height 12px
      display flex

      svg {
        width 100%
        height 100%
      }
    }
  }

  &__select {
    display flex
    align-items center
    margin-right: 30px;
    padding 9px 15px
    border 1px solid var(--border_color)
    border-radius var(--radius)
    cursor pointer
    background transparent
    font-size: 1em;
    line-height: 19px;
    color: var(--black);

    .icon {
      transition var(--transition)
    }

    &--active .icon {
      transform rotate(180deg)
    }

    span {
      margin-right: 5px;
    }
  }

  &__login-btn {
    +below(830px) {
      margin-left 30px
    }
  }

  &__search-btn {
    display flex
    align-items center
    margin-right: 30px;
    position relative
    +below(830px) {
      margin-right 0
    }

    .icon {
      width 24px
      height 24px

      svg {
        width 100%
        height 100%
        svg(var(--dark))
      }
    }

    &:hover svg {
      svg(var(--main_color))
    }
  }

  &__notifications-btn {
    display flex
    align-items center
    margin-right 25px
    position relative
    +below(830px) {
      margin-right 0
    }

    &--unread:after {
      content "";
      width 10px
      height 10px
      border-radius 100%
      background var(--red)
      absolute right top
    }

    .icon {
      width 24px
      height 24px

      svg {
        width 100%
        height 100%
        svg(var(--dark))
      }
    }

    &:hover svg {
      svg(var(--main_color))
    }
  }

  &__menu-btn {
    display flex
    align-items center
    margin-left 15px
    +above(831px) {
      display none
    }
  }

  &__mobile {
    display grid
    width 100%
    grid-gap 10px
    padding-bottom 20px
    +above(831px) {
      display none
    }

    & ^[0]__select {
      margin 0
      justify-content center
    }

    &-search {
      border: 1px solid var(--border_color);
      box-sizing: border-box;
      border-radius: var(--radius);
      height 44px
      padding 0 10px 0 16px
      display flex
      align-items center
      justify-content space-between
      gap 20px
      font-size: 1em;
      line-height: 19px;
      color: var(--black);

      span {
        opacity: 0.3;
      }

      .icon {
        width 24px
        height 24px

        svg {
          width 100%
          height 100%
        }
      }
    }
  }

  &__user {
    display grid
    grid-template-columns 44px 1fr 24px
    align-items center
    grid-gap 10px
    position relative

    ^[0]__right-side & {
      +below(830px) {
        display none
      }
    }

    &--active &-icon {
      transform rotate(180deg)
    }

    &-image {
      width 44px
      height 44px
      border-radius 100%
      object-fit cover
      object-position center
    }

    &-name {
      font-weight: normal;
      font-size: 1em;
      line-height: 19px;
      color: var(--black);
    }

    &-icon {
      width 24px
      height 24px
      transition .2s

      svg {
        width 100%
        height 100%
      }
    }
  }
}
</style>
