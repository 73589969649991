<template>
  <figure class="icon">
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.2833 62.5L46.25 73.125C48.125 75.625 51.875 75.625 53.75 73.125L61.7167 62.5H75C81.9042 62.5 87.5 56.9042 87.5 50V25C87.5 18.0958 81.9042 12.5 75 12.5H25C18.0958 12.5 12.5 18.0958 12.5 25V50C12.5 56.9042 18.0958 62.5 25 62.5H38.2833Z"
        stroke="#323232"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.166 87.5H70.8327"
        stroke="#FF5133"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44 32L56 44"
        stroke="#FF5133"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M56 32L44 44"
        stroke="#FF5133"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "MessageErrorIcon",
};
</script>
