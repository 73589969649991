<template>
  <div class="user-menu" :class="{ 'user-menu--active': active, 'user-menu--static': statical }">
    <router-link :to="{ name: 'account' }" class="user-menu__link">
      <UserIcon />
      <span>Профиль</span>
    </router-link>
    <router-link :to="{ name: 'account_education' }" class="user-menu__link">
      <GraduateIcon />
      <span>Мое обучение</span>
    </router-link>
    <router-link :to="{ name: 'account_completed' }" class="user-menu__link">
      <ArchiveIcon />
      <span>Архив покупок</span>
    </router-link>
    <a @click.prevent="logout" class="user-menu__link">
      <LogoutIcon />
      <span>Выйти</span>
    </a>
  </div>
</template>
<script>
import UserIcon from "components/svg/UserIcon.vue";
import GraduateIcon from "components/svg/GraduateIcon.vue";
import ArchiveIcon from "components/svg/ArchiveIcon.vue";
import LogoutIcon from "components/svg/LogoutIcon.vue";

export default {
  name: "UserMenuComponent",
  props: {
    statical: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
  },
  methods: {
    logout() {
      this.$cookie.delete("apollo-token");
      delete this.$store.state.cookies["apollo-token"];
      if (this.$route.name === "home") {
        location.reload();
        return;
      }
      this.$router.push({ name: "home" }).then(() => {
        location.reload();
      });
    },
  },
  components: { LogoutIcon, ArchiveIcon, GraduateIcon, UserIcon },
};
</script>

<style lang="stylus">
.user-menu {
  background: var(--white);
  box-shadow: 0 0 8px var(--black_o1);
  border-radius: var(--radius);
  absolute right bottom
  display grid
  min-width 200px
  z-index 3
  opacity 0
  visibility hidden
  transition .2s
  transform translateY(100%)

  &--active {
    opacity 1
    visibility visible
    transform translateY(calc(100% + 20px)) // 20 - отступ заголовка
  }

  &--static {
    transform none
    relative left top right bottom
    width 100%
    border-bottom-left-radius 0
    border-bottom-right-radius 0
  }

  &__link {
    display grid
    grid-template-columns 24px auto
    grid-gap 5px
    padding 10px
    cursor pointer

    &:not(:last-child) {
      padding-bottom 8px
      border-bottom 1px solid var(--border_color);
    }
  }
}
</style>
