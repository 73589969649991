<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 12H4" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M10.999 7L16 12.001L10.999 17.001"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 4H18C19.105 4 20 4.895 20 6V18C20 19.105 19.105 20 18 20H16"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "LogoutIcon",
};
</script>
