<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 16L14 12L10 8"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "BreadcrumbArrowIcon",
};
</script>
